.about__container {
    display: grid;
    grid-template-columns: 1fr 1.5fr; /* Adjust the column ratio as needed */
    align-items: center;
    column-gap: 3rem; /* Adjust the gap between the image and description */
}

.about__img {
    max-width: 100%; /* Ensure the image doesn't exceed its original width */
    border-radius: 1.5rem;
    margin-bottom: 2rem;
}

.about__description {
    padding: 10;
    margin-bottom: 2rem;
}

.about__info {
    grid-template-columns: repeat(3, 140px);
    gap: 0.5rem;
    margin-bottom: var(--mb-1);
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .about__container {
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
        justify-items: center;
    }

    .about__img {
        width: 220px;
    }

    .about__info {
        justify-content: center;
    }

    .about__data {
        text-align: center;
    }

    .about__description {
        padding: 0 5rem;
        margin-bottom: 2rem;
    }
}
  /* For medium devices */
  @media screen and (max-width: 576px) {
    .about__info {
        grid-template-columns: repeat(3, 1fr);
    }
  }

  .about__description {
    padding: 0;
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .about__info {
        grid-template-columns: repeat(2, 1fr);
    }
  }